import React from 'react';

var img1 = require('./../../images/background/ptn-1.png');

class Specialization4 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-tb80 bg-white bg-repeat square_shape2 inner-page-padding" style={{ backgroundImage: 'url(' + img1.default + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Nos</span> Especializamos</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                <h4 >
                                Somos fabricantes profesionales de escobillas de carbón, porta escobillas y productos relacionados de 
                                grafito de carbono. Nuestros principales productos son escobillas de carbón, porta escobillas, sellos de carbon,
                                 bujes de carbono, escobillas de carbón mecánico, terminales y soportes, y contactos deslizantes. Manejamos desde 
                                 la materia prima hasta los productos finales. Prometemos un excelente rendimiento de nuestros productos en diferentes 
                                 equipos: motores de corriente continua, motores de pequeña potencia, motores síncronizados, conmutadores, anillos rozantes,
                                 etc. Nuestros clientes son de todo el mundo en diferentes áreas, como tracción de locomotoras ferroviarias, centrales eléctricas 
                                 (carbón, hidroeléctricas, eólicas), herramientas eléctricas, electrodomésticos, automóviles, marina, cemento, acero, textil, papel, 
                                 ascensores, alimentación, química, médica, tintorería, etc.
                                </h4>
                                    {/* {this.props.data.map((item, index) => (
                                        <div className="col-md-3 col-sm-6" key={index}>
                                            <div className="mt-icon-box-wraper m-b30">
                                                <div className="relative icon-count-2 bg-gray p-a30 p-tb50">
                                                    <span className="icon-count-number">{index+1}</span>
                                                    <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                                                        <span className="icon-cell"><img src={item.imgName} alt=""/></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="mt-tilte m-b25">{item.title}</h4>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Specialization4;